import React from "react";

import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../service/ProvedorAutenticacao';

import { LockOutlined } from "@mui/icons-material";

import { toast, ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

import {
    Container,
    CssBaseline,
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
    duration
} from "@mui/material";

import { Link, useHistory } from "react-router-dom";

import UsuarioService from "../../usuario";
import LogarUsuario from '../../logar';

class Login extends React.Component {

    state = {
        username: '',
        senha: '',

    }

    constructor() {
        super();
        this.service = new UsuarioService();
        this.serviceLogar = new LogarUsuario();
    }

    entrar = () => {
    
        if (this.state.username.length == 0) {
            toast.error("Campo usuário não pode ficar em branco.", { autoClose: 1500 });
            return false;
        }
        if (!this.state.senha) {
            toast.error("Campo senha não pode ficar em branco.", { autoClose: 1500 });
            return false;
        }

        this.service.autenticar({
            username: this.state.username,
            senha: this.state.senha,
        }).then(response => {
            //const textForStorage = this.state.username
            // getter  
            this.context.iniciarSessao(response.data);
           // this.props.history.push('/dashboard')
           this.props.history.push('/dashboard')
        }).catch(erro => {
            toast.error(erro.response.data);
        })
 
    }

    render() {
        return (
            <>
                <ToastContainer position="top-right" duration />

                <Container style={{ backgroundColor: '#708090', width: 300}}>
                    <CssBaseline />
                    <Box width={250} 
                        sx={{ 
                            mt: 20,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "white" }}>
                            <LockOutlined />
                        </Avatar>
                        <Typography variant="h5">Login</Typography>
                        <Box sx={{ mt: 1 }} >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Usuário"
                                name="username"
                                autoFocus
                                value={this.state.username}
                                onChange={e => this.setState({ username: e.target.value })}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="senha"
                                name="senha"
                                label="Senha"
                                type="password"
                                value={this.state.senha}
                                onChange={e => this.setState({ senha: e.target.value })}
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={this.entrar}
                            >
                                Login
                            </Button>
                            {/*
                            <Grid container justifyContent={"flex-end"}>
                                <Grid item>
                                    <Link to="/register">Não tem uma conta? Cadastre-se</Link>
                                </Grid>
                            </Grid>
                    */}
                        </Box>
                    </Box>
                </Container>
            </>
        )

    }
}

Login.contextType = AuthContext

export default withRouter(Login)

