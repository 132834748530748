import React, { useState, useEffect } from 'react'
import UsuarioService from '../../service/ApiUsuarioService';
import * as messages from '../../components/toastr'

import { mensagemErro, mensagemSucesso } from '../../components/toastr'
import { toast, ToastContainer } from "react-toastify";

import { useHistory } from 'react-router-dom';

import {
    Container,
    CssBaseline,
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
    duration
} from "@mui/material";

import 'react-toastify/dist/ReactToastify.css';

function CadastroUsuario() {

    const [username, setUsername] = useState(localStorage.getItem('usuario'));
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaRepeticao, setSenhaRepeticao] = useState('');
    const [service, setService] = useState(new UsuarioService());

    let history = useHistory();

    const cadastrar = () => {

        const usuario = {
            username: username,
            email:email,
            senha: senha,
            senhaRepeticao: senhaRepeticao
        }
        
        try {
            service.validar(usuario);
        } catch (erro) {                 
            const msgs = erro.mensagens;
            toast.error(msgs, { autoClose: 1500 });

            msgs.forEach(msg => mensagemErro(msg));
            return false;
        } 

        console.log("1");

        service.salvar(usuario)
            .then(response => {
                toast.error('Usuário cadastrado com sucesso! Faça login para acessar o sistema.', { autoClose: 1500 });

                history.push('/')
            }).catch(erro => {
                const msgs = erro.mensagens;  

                mensagemErro(erro.response.data);
                mensagemErro(erro.response.data);
            })
    }

    const login = () => {
        history('/login');
    }

    const handleChangeusername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeemail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangesenha = (event) => {
        setSenha(event.target.value);
    };

    const handleChangesenhaRepeticao = (event) => {
        setSenhaRepeticao(event.target.value);
    };

    return (
        <div>

            <Container maxWidth="xs" style={{ backgroundColor: '#708090' }}>
                <Box
                    sx={{
                        mt: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Usuário"
                        name="username"
                        autoFocus
                        value={username}
                        onChange={handleChangeusername}
                    />
                    
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={handleChangeemail}
                    />
                    

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="senha"
                        name="senha"
                        label="Senha"
                        type="password"
                        value={senha}
                        onChange={handleChangesenha}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="inputRepitaSenha"
                        name="senha"
                        label="Repete Senha"
                        type="password"
                        value={senhaRepeticao}
                        onChange={handleChangesenhaRepeticao}

                    />

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={cadastrar}
                    >
                        Salvar
                    </Button>

                </Box>
            </Container>
        </div>

    )


}

export default CadastroUsuario;